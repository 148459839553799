.about-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.about-header {
  text-align: center;
  margin-bottom: 3rem;
}

.about-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.about-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  align-items: start;
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.about-card {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.about-card h2 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
  display: inline-block;
}

.about-card p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.about-card p:last-child {
  margin-bottom: 0;
}

.image-section {
  position: sticky;
  top: 2rem;
}

.profile-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-links {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.contact-links a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

.contact-links a:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .about-container {
    padding: 2rem 1rem;
  }

  .about-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .image-section {
    position: static;
    text-align: center;
    order: -1;
  }

  .profile-image {
    max-width: 200px;
  }

  .about-header h1 {
    font-size: 2rem;
  }
}
