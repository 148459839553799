.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

strong a {
  color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.active {
  color: #000; /* Dark color for active state */
  font-weight: bold; /* Optional: makes the text bold */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.custom-navbar {
  background-color: white;
  padding-top: 10px; /* Increase padding above the navbar */
  max-width: 900px; /* Decrease the width of the navbar */
  margin: 0 auto; /* Keep the navbar centered */
  border-bottom: 1px solid black; /* Add a black horizontal line */
}

div.custom-navbar .navbar-nav {
  flex-direction: row;
}

div.custom-navbar .nav-link {
  padding-left: 15px;
  padding-right: 15px;
}

div.custom-navbar .icons a {
  margin-left: 10px;
}


.icons a {
  color: black; /* Icon color */
  margin-left: 10px; /* Spacing between icons */
}

/* Style for the home page container */
.home-container {
  text-align: center; /* Centers inline or inline-block elements */
  padding: 20px; /* Add padding if needed */
  position: relative;
}

/* Style for the centered GIF */
.centered-gif {
  max-width: 900px; /* Ensures the image is responsive and doesn't overflow */
  height: auto; /* Maintains the aspect ratio */
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Centers the image */
}


.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* This makes the container take the full viewport height */
}

.loading-container h1 {
  /* Style for your success message */
  font-size: 2em;
  color: green;
}

/* Fade in */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-out {
  animation: fadeOut 500ms ease-out forwards;
}

.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* Ensure the loading container fills the screen and centers content */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.d-none {
  display: none;
}

.name-title-container {
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Aligns items vertically */
  font-family: 'Roboto', sans-serif;
  font-size: 38px; 
  position: absolute;
  top: 25px; /* Adjust this value to move your text higher */
  left: 50%; /* Centering horizontally */
  transform: translateX(-50%); /* Align center horizontally */
}

.name, .title {
  margin: 0 5px; /* Spacing between texts */
}

.name {
  color: #000; /* Dark color for 'JASPER LEVY' */
  font-weight: 500; /* Bold font weight */
}


.title {
  color: #e05708; /* Lighter gray for 'COMPUTER SCIENCE' */
  font-weight: 500; /* Regular font weight */
  font-size: 20px; /* Regular font weight */
}
.cs {
    color: #606060; /* Lighter gray for 'COMPUTER SCIENCE' */
    font-weight: 500; /* Regular font weight */
    font-size: 20px; /* Regular font weight */
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Aligns items vertically */
    font-family: 'Roboto', sans-serif;
    position: absolute;
    top: 48px; /* Adjust this value to move your text higher */
    left: 50%; /* Centering horizontally */
    transform: translateX(-50%); /* Align center horizontally */
    width: 500px;
}
.content-container {
  max-width: 900px;
  margin: -20px auto 0; /* Moves the div 20px upwards */
  border-top: .5px solid black;
  border-bottom: .5px solid black;
}

@media (max-width: 768px) {
  .content-container {
    border-top: .5px solid black; /* Ensure top border remains */
    border-bottom: .5px solid black; /* Ensure bottom border remains */
    transform: none; /* Prevents the div from moving up on smaller screens */
  }
}

.two-column-container {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto; /* Center the container */
  gap: 45px; /* Spacing between columns */
  border-bottom: 1px solid black;
  padding-bottom: 30px; /* Adjust the value as needed */
}

.text-column {
  flex: 1; /* Takes up half of the container */
  text-align: left; /* Left-aligns the text */
  padding-left: 40px;
  font-size: 14px;
}

.section-title {
  font-size: 24px
}

@media (max-width: 768px) {
  .text-column {
    font-size: 10px; /* Adjust this value based on your design needs */
    padding-left: 0px
  }
  .section-title {
    font-size: 14px; /* Adjust this value based on your design needs */
  }
}

.image-column {
  flex: 1; /* Takes up the other half */
  display: flex;
  justify-content: center; /* Center the image in the column */
  align-items: center; /* Vertically center */
  padding-right: 60px
}

.image-column img {
  max-width: 100%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
}

.three-column-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjusts space between columns */
  margin: 0 auto; /* Centers the container */
  max-width: 900px; /* Adjust this width based on your design needs */
  gap: 5px 5px; /* Reduced vertical gap */
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 40px;
}
.column {
  flex: 1 0 100%; /* Each column takes full width on small screens */
  max-width: 100%;
  padding: 10px 10px 10px 10px; /* Adjust padding as needed */
  margin-bottom: 0; /* Remove bottom margin if it's not needed */
}
/* Media query for sma
ll screens */
@media (max-width: 768px) {
  .column {
    flex: 1 0 100%; /* Each column takes full width on small screens */
    max-width: 100%;
  }
}
.column {
  flex: 1 1 30%; /* Each column will take up roughly 30% of the container width, adjusting for flex-basis */
  max-width: 30%; /* Ensures that no column grows beyond 30% width */
}

.column {
  flex: 1; /* Each column takes equal width */
  padding: 10px; /* Optional padding for spacing inside columns */
  /* Add additional styling as needed */
}

.image-container {
  position: relative;
  width: 100%;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.90);
  color: black;
  transition: .5s ease;
  opacity: 0;
  display: flex;
  align-items: center;  /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  font-size: 12px;
}
.image-wrapper {
  padding-top: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid black; /* Adds a black border to the bottom */
  max-width: 900px;
  margin: 0 auto; /* Centers the wrapper horizontally */
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
}

@media (max-width: 768px) {
  .image-wrapper {
    border-bottom: none; /* Removes the border from the image wrapper on small screens */
    padding-top: 0px;
    margin-top: 20px; /* Adjust this value as needed to move the image wrapper down */
  }
}

.image-container:hover .overlay {
  opacity: 1;
}

.acknowledgment {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8em;
  color: #333; /* Adjust the color as per your site's theme */
  background-color: #ffffff; /* Optional, for better visibility */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; /* You can adjust the width as needed */
  /* Add other styling properties as needed */
}

.overlay .text {
  padding: 20px; /* Adjust the padding as needed */
  /* Other styles (if any) */
}

.two-column-container-projects {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto; /* Center the container */
  gap: 45px; /* Spacing between columns */
  padding-top: 70px; /* Adjust the value as needed */
}

.two-column-container-research-projects {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto; /* Center the container */
  gap: 45px; /* Spacing between columns */
  padding-top: 40px; /* Adjust the value as needed */
  border-bottom: 1px solid black;
  padding-bottom: 30px; /* Adjust the value as needed */
  transition: transform 0.3s ease-in-out;
}

.two-column-container-research-projects:hover {
  transform: scale(1.05);
}

.text-column-research {
  flex: 1; /* Takes up half of the container */
  text-align: left; /* Left-aligns the text */
  padding-left: 60px;
  font-size: 10px
}

.about-div {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto; /* Center the container */
  gap: 45px; /* Spacing between columns */
  padding-top: 40px; /* Adjust the value as needed */
}

.two-column-container {
  margin-top: 0; /* Remove top margin to reduce space */
  padding: 2%;
}
@media (max-width: 768px) {
  .two-column-container {
    padding-top: 0; /* Remove top padding on small screens */
    gap: 45px;
  }
}
/* For Responsive Displays */
@media (max-width: 768px) {
  div.custom-navbar .navbar-nav {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  div.custom-navbar .icons {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  div.custom-navbar .icons a {
    font-size: 0.8em;
  }
  .centered-gif {
      display: none; /* Hide the GIF image */
  }
  .two-column-container {
    padding-top: 2%; /* Add top padding to the container */
  }
  .name-title-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .cs {
      position: static; /* Remove absolute positioning on small screens */
      transform: none; /* Reset transform */
      margin-top: 10px; /* Add some space between the texts */
  }

  .image-column{
      width: 150px; /* Optional: Adjust image size */
      height: auto;
      padding-right: 0;
  }
  .content-container {
    border-bottom: 0px solid rgb(246, 243, 243);
    border-top: 0px solid rgb(246, 243, 243);
  }
  .about-div {
    padding-left: 20px;
    padding-right: 20px;
  }
  .two-column-container-research-projects {
    padding-right: 50px;
  }
  .two-column-container-research-projects p{
    display: none;
  }
  #hide-on-small {
    display: none;
  }
  .two-column-container-projects {
    padding-left: 20px; /* Adjust the padding value as needed */
    padding-right: 20px;
  }

}

@media (max-width: 768px) {
  .three-column-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0px; /* Ensures no vertical gap */
  }
  .three-column-container .column {
    margin-bottom: 0; /* Removes bottom margin from columns */
  }
}


.gallery-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
  width: 1000px;
  margin: 0 auto;
  padding-left:50px; /* Adds padding on both sides */
  padding-right :50px; /* Adds padding on both sides */
  overflow: auto;
}

@media (max-width: 960px) {
  .gallery-container {
      width: calc(100% - 20px); /* Take full width minus some padding */
      padding: 0 10px; /* Smaller padding on smaller screens */
      padding-top: 40px;
      padding-bottom: 40px;
  }
}

.custom-overlay__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.coursework-section {
  max-width: 900px;
  margin: 30px auto 60px auto;
  text-align: center;
}

.coursework-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.coursework-list {
  text-align: center;
  padding-top: 10px;
}



.course-button {
  background-color: #ccc;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .course-button {
    font-size: 12px;
    padding: 3px 10px;
  }
}

.course-button:hover,
.course-button:focus {
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


