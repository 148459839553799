.coursework-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.intro-section {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.intro-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.intro-text strong {
  color: #000;
  background: linear-gradient(120deg, rgba(135, 187, 162, 0.2) 0%, rgba(135, 187, 162, 0.2) 100%);
  padding: 0.1em 0.3em;
  border-radius: 3px;
}

.course-categories {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 3rem;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.category-header {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1.2rem;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid;
  text-align: left;
  align-self: flex-start;
  margin-left: 1rem;
}

.category-header.systems { border-color: #364958; }
.category-header.math { border-color: #3b6064; }
.category-header.ml { border-color: #55828b; }
.category-header.business { border-color: #87bba2; }

.course-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.1rem;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.course-button {
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.7rem;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 0.95rem;
  text-align: left;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  line-height: 1.3;
  white-space: nowrap;
}

.course-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-button strong {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  margin-left: 0.5em;
  font-size: 0.85em;
}

@media (max-width: 1200px) {
  .course-categories {
    gap: 2.5rem;
  }
}

@media (max-width: 768px) {
  .coursework-container {
    padding: 2rem 1rem;
  }

  .intro-section {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .course-categories {
    gap: 2rem;
    padding: 0 0.5rem;
  }

  .category-header {
    margin-left: 0.5rem;
  }

  .course-grid {
    padding: 0 0.5rem;
  }

  .course-button {
    padding: 0.5rem 0.7rem;
  }
}
