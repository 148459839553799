.ederm-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 6rem;
}

.project-header {
  text-align: center;
  margin-bottom: 4rem;
}

.project-header h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.header-image {
  max-width: 150px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

section {
  margin-bottom: 2rem;
}

section h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #444;
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.feature-item {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.feature-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
}

.feature-item p {
  color: #666;
  line-height: 1.6;
}

.miro-embed {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  aspect-ratio: 16/9;
}

.miro-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .ederm-page {
    padding: 2rem;
  }

  .project-header h1 {
    font-size: 2rem;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }
}
